import { Container, Row, Col } from "react-bootstrap";
import navIcon1 from "../../assets/img/linkedin.png";
import navIcon2 from "../../assets/img/github-logo.png";
import navIcon3 from "../../assets/img/twitter-sign.png";
import "../Footer/Footer.css";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col className="text-center text-sm-center">
            <div className="social-icon">
              {/* <a href="https://www.linkedin.com/in/jackdoy/" target="_blank" rel="noreferrer">
                <img src={navIcon1} alt="My LinkedIn" />
              </a> */}
              <a href="https://github.com/JD0x2e" target="_blank" rel="noreferrer">
                <img src={navIcon2} alt="My GitHub" />
              </a>
              <a href="https://twitter.com/JD0x2e" target="_blank" rel="noreferrer">
                <img src={navIcon3} alt="My Twitter" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
