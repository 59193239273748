import skill1 from "../../assets/img/solidity-original.svg";
import skill2 from "../../assets/img/ts.png";
import skill3 from "../../assets/img/react-original.svg";
import skill4 from "../../assets/img/nextjs-icon.svg";
import skill5 from "../../assets/img/express-original.svg";
import skill6 from "../../assets/img/mongodb-original.svg";
import skill7 from "../../assets/img/nodejs-original.svg";
import skill8 from "../../assets/img/html.png";
import skill9 from "../../assets/img/css-3.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../Skills/Skills.css";

export const Skills = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5,
    },
    laptop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    largetablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>My Skills</h2>
              <br />
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                <div className="item">
                  <img src={skill1} alt="" />
                  <h6>Solidity</h6>
                </div>
                <div className="item">
                  <img src={skill2} alt="" />
                  <h6>TypeScript</h6>
                </div>
                <div className="item">
                  <img src={skill3} alt="" />
                  <h6>React</h6>
                </div>
                <div className="item">
                  <img src={skill4} alt="" />
                  <h6>Next.js</h6>
                </div>
                <div className="item">
                  <img src={skill5} alt="" />
                  <h6>Express</h6>
                </div>
                <div className="item">
                  <img src={skill6} alt="" />
                  <h6>MongoDB</h6>
                </div>
                <div className="item">
                  <img src={skill7} alt="" />
                  <h6>Node.js</h6>
                </div>
                <div className="item">
                  <img src={skill8} alt="" />
                  <h6>HTML</h6>
                </div>
                <div className="item">
                  <img src={skill9} alt="" />
                  <h6>CSS</h6>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
