import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, toUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <a href={toUrl} alt="" target="_blank" rel="noreferrer">
          <img src={imgUrl} alt="" />
        </a>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <a href={toUrl} alt="" target="_blank" rel="noreferrer">
            {description}
          </a>
        </div>
      </div>
    </Col>
  );
};
