import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../../assets/img/cbrecruitment.png";
import projImg2 from "../../assets/img/radiantcapital.png";
import projImg3 from "../../assets/img/coinswapper.png";
import projImg4 from "../../assets/img/trustm3.png";
import projImg5 from "../../assets/img/bobagaming.png";
import projImg6 from "../../assets/img/libraryofbooks.png";
import colorSharp2 from "../../assets/img/color-sharp2.png";
import "../Projects/Projects.css";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      description: "CBRecruitment",
      imgUrl: projImg1,
      toUrl: "https://cbrecruitment.com",
    },
    {
      description: "Radiant Capital",
      imgUrl: projImg2,
      toUrl: "https://radiant.capital",
    },
    {
      description: "CoinSwapper",
      imgUrl: projImg3,
      toUrl: "https://coinswapper.netlify.app",
    },
    {
      description: "Boba Gaming",
      imgUrl: projImg4,
      toUrl: "https://boba-gaming.netlify.app",
    },
    {
      description: "TrustM3",
      imgUrl: projImg5,
      toUrl: "https://trustm3.xyz",
    },
    {
      description: "Library of Books",
      imgUrl: projImg6,
      toUrl: "https://libraryofbooks.netlify.app",
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                // <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <div className={isVisible}>
                  <h2>Projects</h2>
                  <br />
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Tab.Content className={isVisible}>
                      {/* <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}> */}
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
