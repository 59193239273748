import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import navIcon1 from "../../assets/img/linkedin.png";
import navIcon2 from "../../assets/img/github-logo.png";
import navIcon3 from "../../assets/img/twitter-sign.png";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";
import "../NavBar/NavBar.css";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                className={activeLink === "home" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("home")}>
                Home
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={activeLink === "skills" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("skills")}>
                Skills
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className={activeLink === "projects" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("projects")}>
                Projects
              </Nav.Link>
              {/* <Nav.Link
                href="#blog"
                className={activeLink === "blog" ? "active navbar-link" : "navbar-link"}
                onClick={() => onUpdateActiveLink("blog")}>
                Blog
              </Nav.Link> */}
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                {/* <a href="https://www.linkedin.com/in/jackdoy/" target="_blank" rel="noreferrer">
                  <img src={navIcon1} alt="My LinkedIn" />
                </a> */}
                <a href="https://github.com/JD0x2e" target="_blank" rel="noreferrer">
                  <img src={navIcon2} alt="My GitHub" />
                </a>
                <a href="https://twitter.com/JD0x2e" target="_blank" rel="noreferrer">
                  <img src={navIcon3} alt="My Twitter" />
                </a>
              </div>
              <HashLink to="#connect">
                <button className="connect-btn">
                  <span className="connect-btn-text">Let's Connect</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
