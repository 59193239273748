import React, {useEffect} from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Container, Row, Col } from "react-bootstrap";
import TrackVisibility from "react-on-screen";

import "animate.css";
import "../Contact/Contact.css";

export const Contact = () => {
  const [state, handleSubmit] = useForm(process.env.REACT_APP_FORMSPREE_ID);

   useEffect(() => {
    if (state.succeeded) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [state.succeeded]);

  
  return (
    <section className="contact" id="connect">
      <Container>
        <Col size={12} md={6} className="img-container">
          <TrackVisibility>
          </TrackVisibility>
        </Col>
        <Col size={22} md={12} className="contact-container">
          <TrackVisibility>
            {({ isVisible }) => (
              <div className={isVisible}>
                <h2>Get In Touch</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={10} sm={6} className="px-1">
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                      />
                      <ValidationError 
                        prefix="First Name" 
                        field="firstName"
                        errors={state.errors}
                      />
                    </Col>
                    <Col size={10} sm={6} className="px-1">
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                      />
                      <ValidationError 
                        prefix="Last Name" 
                        field="lastName"
                        errors={state.errors}
                      />
                    </Col>
                    <Col size={10} sm={6} className="px-1">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                      />
                      <ValidationError 
                        prefix="Email" 
                        field="email"
                        errors={state.errors}
                      />
                    </Col>
                    <Col size={10} sm={6} className="px-1">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Phone No."
                      />
                      <ValidationError 
                        prefix="Phone No." 
                        field="phone"
                        errors={state.errors}
                      />
                    </Col>
                    <Col size={10} className="px-1">
                      <textarea
                        rows="6"
                        id="message"
                        name="message"
                        placeholder="Message"
                      ></textarea>
                      <ValidationError 
                        prefix="Message" 
                        field="message"
                        errors={state.errors}
                      />
                      <button type="submit" disabled={state.submitting}>
                        Submit
                      </button>
                    </Col>
                  </Row>
                </form>
                {state.succeeded && <p>Message sent successfully!</p>}
              </div>
            )}
          </TrackVisibility>
        </Col>
      </Container>
    </section>
  );
};
